import { useCallback, useEffect, useRef } from 'react';
import { useMicrophone } from '../..';
import {
    AvatarModalName,
    AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';
import config from '../../../../../apps/mooc-frontend/src/consts/config';
import { speechRecognitionApi } from '../../../../../apps/mooc-frontend/src/services';
import {
    PromiseWithResolvers,
    promiseWithResolvers,
} from '../../../../core/src/utils/promise';
import { useStore, useStoreWithArray } from '../../stores';
import useASR from '../../templates/AvatarTemplate/useASR';
import {
    InteractionContextAPI,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { InteractionActions } from '../../utils/interaction/useInteractionAgent';
import { useWebSocket } from '../useWebsocket';
import {
    isConfiguredEvent,
    isRapportAsr as _isRapportAsr,
    isRemoteAsr as _isRemoteAsr,
    isSpeechTranscriptEvent,
    isStartedEvent,
    isStoppedEvent,
    parseWebsocketEvent,
} from './speech.utils';

export interface SpeechRecognitionProps {
    sessionId: string;

    transcriber_config: TranscriberConfig;
    processor_configs: TranscriptionProcessorConfig[];

    setModal: (modal: AvatarModalName) => void;
}

export const useSpeechRecognition = ({
    setModal,
    sessionId,
    processor_configs,
    transcriber_config,
}: SpeechRecognitionProps) => {
    const onConfiguredPromiseRef = useRef<PromiseWithResolvers<
        ConfigureTranscriptionResponse
    > | null>(null);
    const onStartedPromiseRef = useRef<PromiseWithResolvers<
        StartedTranscriptionResponse
    > | null>(null);
    const onStoppedPromiseRef = useRef<PromiseWithResolvers<
        StoppedTranscriptionResponse
    > | null>(null);
    const isProcessingRef = useRef(false);

    const isRapportAsr = _isRapportAsr(transcriber_config);
    const isRemoteAsr = _isRemoteAsr(transcriber_config) && !isRapportAsr;

    const { act } = useInteractionContext(InteractionContextAPI);
    const { micListeners } = useStoreWithArray(['micListeners']);
    const { onMessageRecognised, sendBuffer, resetBuffer } = useASR();

    const onError = useCallback(
        (_error: Error) => {
            resetBuffer();
            setModal(
                AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE.code,
            );
        },
        [setModal, resetBuffer],
    );

    const onMessage = useCallback(
        (message: string) => {
            const websocketEvent = parseWebsocketEvent(message);
            if (!websocketEvent) {
                console.warn(`[ASR]: unknown message received`, message);
                const error = new Error(`Unknown message received: ${message}`);
                [
                    onConfiguredPromiseRef,
                    onStartedPromiseRef,
                    onStoppedPromiseRef,
                ].map(ref => ref.current?.reject(error));
                return;
            }

            if (isSpeechTranscriptEvent(websocketEvent)) {
                if (!websocketEvent.is_final) return;
                console.info(`[ASR]: final speech transcript`, websocketEvent);
                // TODO: use the first alternative for now?
                onMessageRecognised(
                    websocketEvent.alternatives[0],
                    websocketEvent.language_code,
                    true,
                );
                return;
            }

            console.log(`[ASR]: websocket event received`, websocketEvent);
            if (isConfiguredEvent(websocketEvent)) {
                onConfiguredPromiseRef.current?.resolve(websocketEvent);
            }

            if (isStartedEvent(websocketEvent)) {
                onStartedPromiseRef.current?.resolve(websocketEvent);
            }

            if (isStoppedEvent(websocketEvent)) {
                onStoppedPromiseRef.current?.resolve(websocketEvent);

                // This will be called once the ASR service has processed all
                // the audio.
                const meta = websocketEvent.audio_url
                    ? {
                          audio_url: websocketEvent.audio_url,
                          console_audio_url: websocketEvent.console_audio_url,
                      }
                    : {};

                console.info(`[ASR]: sending buffered transcripts`, meta);
                sendBuffer(false, meta);
            }
        },
        [
            sendBuffer,
            onMessageRecognised,
            onConfiguredPromiseRef,
            onStartedPromiseRef,
            onStoppedPromiseRef,
        ],
    );

    const { send, sendEvent, connect, disconnect } = useWebSocket({
        generateWebsocketUrl:
            speechRecognitionApi.generateAuthenticatedWebsocketUrl,
        onMessage: onMessage,
        onError: onError,
    });

    const {
        sendBufferedAudio,
        destroy: destroyMicrophone,
        initialize: initializeMicrophone,
        setShouldRecord: toggleMicrophone,
    } = useMicrophone({
        onError,
        onData: send,
        sampleRate: config.REACT_APP_SPEECH_RECOGNITION_API_SAMPLE_RATE,
    });

    const sendConfigureEvent = useCallback(async () => {
        console.debug(`[ASR]: sending configure event`, transcriber_config);
        const configureTime = performance.now();

        const resolvers = promiseWithResolvers<
            ConfigureTranscriptionResponse
        >();
        onConfiguredPromiseRef.current = resolvers;

        sendEvent({
            type: 'configure',
            session_id: sessionId,
            transcriber_config: {
                ...transcriber_config,
                sample_rate:
                    config.REACT_APP_SPEECH_RECOGNITION_API_SAMPLE_RATE,
            },
            processor_configs: processor_configs,
        });

        await onConfiguredPromiseRef.current?.promise;
        onConfiguredPromiseRef.current = null;
        console.info(
            `[ASR]: Server took ${performance.now() -
                configureTime}ms to respond to configure event`,
        );
    }, [
        sessionId,
        sendEvent,
        processor_configs,
        transcriber_config,
        onConfiguredPromiseRef,
    ]);

    const sendStartEvent = useCallback(async () => {
        console.debug(`[ASR]: sending start event`);
        const startTime = performance.now();

        const resolvers = promiseWithResolvers<StartedTranscriptionResponse>();
        onStartedPromiseRef.current = resolvers;

        sendEvent({ type: 'start' });

        await onStartedPromiseRef.current?.promise;
        onStartedPromiseRef.current = null;
        console.info(
            `[ASR]: Server took ${performance.now() -
                startTime}ms to respond to start event`,
        );
    }, [sendEvent, onStartedPromiseRef]);

    const sendStopEvent = useCallback(async () => {
        console.debug(`[ASR]: sending stop event`);
        const stopTime = performance.now();

        const resolvers = promiseWithResolvers<StoppedTranscriptionResponse>();
        onStoppedPromiseRef.current = resolvers;

        sendEvent({ type: 'stop' });

        await onStoppedPromiseRef.current?.promise;
        onStoppedPromiseRef.current = null;

        console.info(
            `[ASR]: Server took ${performance.now() -
                stopTime}ms to respond to stop event`,
        );
    }, [sendEvent, onStoppedPromiseRef]);

    const toggleSpeechRecognition = useCallback(
        async (shouldListen: boolean, shouldConfigure = false) => {
            if (isProcessingRef.current) {
                console.warn(
                    '[ASR]: toggle speech recognition is called, however it already processing a request.',
                    'waiting for the current request to complete',
                    {
                        shouldListen,
                        shouldConfigure,
                    },
                );

                // wait for the current request to complete
                await Promise.all(
                    [
                        onConfiguredPromiseRef.current?.promise,
                        onStartedPromiseRef.current?.promise,
                        onStoppedPromiseRef.current?.promise,
                    ].filter(Boolean),
                );
            }

            isProcessingRef.current = true;
            const startTime = performance.now();
            console.info('[ASR]: toggling speech recognition', {
                shouldListen,
                shouldConfigure,
            });

            try {
                if (shouldConfigure) {
                    await sendConfigureEvent();
                    return;
                }

                if (shouldListen) {
                    act(InteractionActions.transcribe_start);

                    toggleMicrophone(shouldListen);
                    await sendStartEvent();
                    sendBufferedAudio();

                    return;
                }

                // delay stopping the microphone to ensure we capture
                // and send all the audio.
                await new Promise(resolve =>
                    setTimeout(
                        resolve,
                        config.REACT_APP_STOP_MICROPHONE_DELAY_MS,
                    ),
                );

                toggleMicrophone(shouldListen);
                await sendStopEvent();
                act(InteractionActions.transcribe_success);
            } catch (err) {
                // Fire it to clean the state in case of exception
                act(InteractionActions.transcribe_failed);

                // reset the references here as the promise may have failed.
                onConfiguredPromiseRef.current = null;
                onStartedPromiseRef.current = null;
                onStoppedPromiseRef.current = null;

                const error =
                    err instanceof Error
                        ? err
                        : new Error(
                              `error toggling speech recognition: ${err}`,
                          );
                console.error(
                    '[ASR]: error occurred while toggling speech recognition',
                    error,
                    { shouldListen },
                );

                // turn off the microphone and disconnect from the websocket
                // then show the model to the user
                toggleMicrophone(false);
                disconnect();
                onError(error);
            } finally {
                isProcessingRef.current = false;
                console.info(
                    `[ASR]: took ${performance.now() -
                        startTime}ms to toggle speech recognition`,
                    { shouldListen, shouldConfigure },
                );
            }
        },
        [
            act,
            onError,
            disconnect,
            sendStopEvent,
            sendStartEvent,
            toggleMicrophone,
            sendBufferedAudio,
            sendConfigureEvent,
            onStartedPromiseRef,
            onStoppedPromiseRef,
            onConfiguredPromiseRef,
        ],
    );

    const onWebsocketStateChanged = useCallback(
        async (connected: boolean, isNewConnection: boolean) => {
            if (!connected) return;

            const isMicOn = useStore.getState().isMicOn;
            isNewConnection && (await toggleSpeechRecognition(isMicOn, true));

            if (!isMicOn) return;
            console.info(
                '[ASR]: websocket connected and mic is on, sending start event',
            );
            await toggleSpeechRecognition(isMicOn);
        },
        [toggleSpeechRecognition],
    );

    const retryAsr = useCallback(
        async (onSuccess: () => void, onRetryComplete?: () => void) => {
            if (!isRemoteAsr) return;
            console.info('[ASR]: received action to retry ASR');

            const isInitialized = await initializeMicrophone();
            if (!isInitialized) {
                // Add a bit of delay so the user can notice the try again
                // button is doing something.
                if (onRetryComplete) {
                    setTimeout(onRetryComplete, 500);
                }
                return;
            }

            const onStateChanged = (
                connected: boolean,
                isNewConnection: boolean,
            ) => {
                connected && onSuccess();
                onRetryComplete?.();
                onWebsocketStateChanged(connected, isNewConnection);
            };
            await connect(onStateChanged);
        },
        [connect, isRemoteAsr, onWebsocketStateChanged, initializeMicrophone],
    );

    useEffect(() => {
        if (isRemoteAsr) {
            console.debug('[ASR]: setting up the microphone');
            initializeMicrophone();
        }

        return () => {
            if (isRemoteAsr) {
                console.debug('[ASR]: tearing down the microphone');
                destroyMicrophone();
            }
        };
    }, [initializeMicrophone, destroyMicrophone, isRemoteAsr]);

    useEffect(() => {
        if (isRemoteAsr) {
            console.debug('[ASR]: setting up the listeners');
            micListeners.add(toggleSpeechRecognition);
        }

        return () => {
            if (isRemoteAsr) {
                console.debug('[ASR]: tearing down the listeners');
                micListeners.delete(toggleSpeechRecognition);
            }
        };
    }, [micListeners, isRemoteAsr, toggleSpeechRecognition]);

    useEffect(() => {
        if (isRemoteAsr) {
            console.debug('[ASR]: setting up the remote asr connection');
            connect(onWebsocketStateChanged);
        }

        return () => {
            if (isRemoteAsr) {
                console.debug('[ASR]: tearing down the remote asr connection');
                disconnect();
            }
        };
    }, [isRemoteAsr, connect, disconnect, onWebsocketStateChanged]);

    return { isRapportAsr, retryAsr };
};
